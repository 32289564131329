const getSetting = () => {
	if (process.env.NODE_ENV === 'production') {
		console.log("production env")
		return {
			firebaseConfig: {
			    apiKey: "AIzaSyAIUcrJNBk-0upAnY8OJF7mT7TtC8rLl5M",
    			authDomain: "loxamtraining-prod.firebaseapp.com",
    			databaseURL: "https://loxamtraining-prod.firebaseio.com",
    			projectId: "loxamtraining-prod",
    			storageBucket: "loxamtraining-prod.appspot.com",
    			messagingSenderId: "59949564466",
    			appId: "1:59949564466:web:92874672152b570ba5722b"
			}
		}
	} else {
		console.log("dev env")
		return {
			firebaseConfig: {
			    apiKey: "AIzaSyD0kQDuloDz3DXg1a3vP_kowm8FzvFVa-U",
    			authDomain: "loxamtraining-dev.firebaseapp.com",
    			databaseURL: "https://loxamtraining-dev.firebaseio.com",
    			projectId: "loxamtraining-dev",
    			storageBucket: "loxamtraining-dev.appspot.com",
    			messagingSenderId: "261881685591",
    			appId: "1:261881685591:web:16fa5a15a86024f883e512"
			}
		}
	}
}


export default getSetting()