import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { TextData, EditData } from 'loxamtraining-data'

import { Page, PageSection, PageTitleBar, Table } from '../../lib/apptheme'


export const OrderList = () => {
	const data: any[] = []
	const isLoading: boolean = false
	return (
		<Page>
			<PageTitleBar>
				<div className="d-flex justify-content-between">
					<h1 className="page-title mr-sm-auto">Commandes</h1>
				</div>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Table data={data} isLoading={isLoading}>
							<TextData name="id" label="Numéro de commande" />
							<TextData name="createdAt" label="Date" />
							<TextData name="trainingorganizationId" label="Organisme de formation" />
							<TextData name="fullfillmentStatus" label="Exécution" />
							<EditData path="/orders" name="id" />
						</Table>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}