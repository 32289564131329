import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { FirestoreClient } from 'loxamtraining-core'
import { Page, PageTitleBar, PageSection, Table } from '../partials'


interface PageListProps {
	title: string,
	addButton?: {
		name: string,
		path: string
	},
	path: string,
	filterData?: (data: any) => any
}

const PageList: React.FC<PageListProps> = props => {
	const firestoreClient = new FirestoreClient()
	const [isLoading, setLoading] = useState(true)
	const [data, setData] = useState([])
	
	useEffect(() => {
		firestoreClient.getAll(props.path).then((data) => {
			if (props.filterData) {
				setData(props.filterData(data))
			} else {
				setData(data)
			}
			setLoading(false)
		})
	}, [])

	return (
		<Page>
			<PageTitleBar>
				<div className="d-flex justify-content-between">
					<h1 className="page-title mr-sm-auto">{props.title}</h1>
					{
						props.addButton && (
							<div className="btn-toolbar">
								<Link to={props.addButton.path} className="btn btn-secondary"><i className="fa fa-plus"></i> {props.addButton.name}</Link>
							</div>
						)
					}
				</div>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Table data={data} isLoading={isLoading}>
							{props.children}
						</Table>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}

export default PageList