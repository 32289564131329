import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { FirestoreClient, useUserContext, useAsyncData, useAsyncData2, useAsyncOptions } from 'loxamtraining-core'
import { Form, SelectField, ChoiceField } from 'loxamtraining-form'
import { TextData, EditData } from 'loxamtraining-data'


import { Page, PageSection, PageTitleBar, Table, Edit } from '../../lib/apptheme'
import { getStoreFilteredData } from '../utils'

const platforms = [
  { value: 'R482', label: 'R482 / Engins de chantier Terrassement' },
  { value: 'R486', label: 'R486 / Nacelle' },
  { value: 'R489', label: 'R489 / Chariots' },
  { value: 'R484', label: 'R484 / Pont roulant' },
  { value: 'R485', label: 'R485 / Transpalette gerbeur accompagnant' },
  { value: 'echafaudageroulant', label: 'Echafaudage Roulant'},
  { value: 'travailhauteur', label: 'Travail en hauteur'},
]


const trainingCategoriesOptions: any = {
	'R482': ['A', 'B1', 'C1', 'F', 'G'],
	'R486': ['A', 'B', 'C'],
	'R489': ['3'],
	'R484': ['1'],
	'R485': ['1', '2']
}


const CategoriesData = (props: any) => (
	<React.Fragment>
		{
			props.data ? props.data.join(', ') : '-'
		}
	</React.Fragment>
)

const PlateFormData = (props: any) => <React.Fragment>{props.data ? platforms.filter(platform => platform.value === props.data)[0].label : '-'}</React.Fragment>

export const PlatformList = () => {
	const [isLoading, data] = useAsyncData('/platforms')

	const user: any | null = useUserContext()

	const filteredData: any[] = getStoreFilteredData(data, user, 'storeId')

	return (
		<Page>
			<PageTitleBar>
				<div className="d-flex justify-content-between">
					<h1 className="page-title mr-sm-auto">Plateaux technique</h1>
					{
						(user.roles.admin || user.roles.agent) && (
							<div className="btn-toolbar">
								<Link to="/platforms/add" className="btn btn-secondary"><i className="fa fa-plus"></i> Ajouter un plateau technique</Link>
							</div>
						)
					}
				</div>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Table data={filteredData} isLoading={isLoading}>
							<TextData name="storeId" label="Site de formation" refPath="/stores" keyName="name" />
							<PlateFormData name="type" label="Plateau technique" />
							<CategoriesData name="trainingCategories" label="Catégories CACES &#x24C7;" />
							<EditData path="/platforms" name="id" />
						</Table>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}


const OnchangeValues = (props: any) => {
	const { values, setFieldValue }: {values: any, setFieldValue: any} = useFormikContext()	

	React.useEffect(() => {
		if (!props.prevValues && values.type) {
			props.setPrevValues(values)
			//Handle if there is no category option
			if (trainingCategoriesOptions[values.type]) {
				props.setCategoryOptions(trainingCategoriesOptions[values.type].map((option: string) => ({value: option, label: option})))
			} else {
				props.setCategoryOptions([])
			}
		}

		if (props.prevValues && values.type && props.prevValues.type !== values.type) {
			setFieldValue('trainingCategories', undefined)
			//Handle if there is no category option
			if (trainingCategoriesOptions[values.type]) {
				props.setCategoryOptions(trainingCategoriesOptions[values.type].map((option: string) => ({value: option, label: option})))
			} else {
				props.setCategoryOptions([])
			}
		}

		props.setPrevValues(values)
	}, [values])

	return null
}

export const PlatformAdd = () => {
	const [categoryOptions, setCategoryOptions]: [string[], any] = useState([])
	const [prevValues, setPrevValues]: [any, any] = useState(undefined)
	let [isLoadingOptions, options] = useAsyncOptions('/stores', 'id', 'name')

	const user: any | null = useUserContext()

	//Filter on store id base on the use roles
	if (options && user.roles.agent && user.roles.admin === false){
		options = options.filter((item: any) => user.stores.indexOf(item.value) > -1)
	}

	const firestoreClient = new FirestoreClient()
	const history = useHistory()
	const handleSubmit = (values: any, actions: any) => {
		firestoreClient.add('/platforms', values).then((id) => {
			history.push(`/platforms/${id}`)
		})	
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Ajouter une plateforme</h1>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Form onSubmit={handleSubmit} initialValues={{}}>
							<ChoiceField name="storeId" label="Site de formation" options={options} isLoading={isLoadingOptions} />
							<SelectField name="type" label="Type de plateforme" required>
								<option>Select</option>
								{
									platforms.map((platform, index) => <option key={index} value={platform.value}>{platform.label}</option>)
								}
							</SelectField>
							{
								categoryOptions.length > 0 && (
									<ChoiceField name="trainingCategories" options={categoryOptions} label="Catégories de CACES &#x24C7;" required isMulti />
								)
							}
							<OnchangeValues prevValues={prevValues} setPrevValues={setPrevValues} setCategoryOptions={setCategoryOptions} />
						</Form>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}


export const PlatformEdit = () => {
	const firestoreClient = new FirestoreClient()
	const [categoryOptions, setCategoryOptions]: [string[], any] = useState([])
	const [prevValues, setPrevValues]: [any, any] = useState(undefined)
	let [isLoadingOptions, options] = useAsyncOptions('/stores', 'id', 'name')

	const user: any | null = useUserContext()

	//Filter on store id base on the use roles
	if (options && user.roles.agent && user.roles.admin === false){
		options = options.filter((item: any) => user.stores.indexOf(item.value) > -1)
	}

	const { id } = useParams()
	const [isLoading, data, setData, setLoading] = useAsyncData2(`/platforms/${id}`)

	const handleSubmit = (values: any, actions: any) => {
		firestoreClient.update(`/platforms/${id}`, values).then((data: any) => {
			setData(data)
			actions.setSubmitting(false)
		})
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Détail de la plateforme</h1>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Edit handleSubmit={handleSubmit} data={data} isLoading={isLoading}>
							<ChoiceField name="storeId" label="Site de formation" options={options} isLoading={isLoadingOptions} />
							<SelectField name="type" label="Type de plateforme" required>
								{
									platforms.map((platform, index) => <option key={index} value={platform.value}>{platform.label}</option>)
								}
							</SelectField>
							{
								categoryOptions.length > 0 && (
									<ChoiceField name="trainingCategories" options={categoryOptions} label="Catégories de CACES &#x24C7;" required isMulti />
								)
							}
							<OnchangeValues prevValues={prevValues} setPrevValues={setPrevValues} setCategoryOptions={setCategoryOptions} />
						</Edit>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}