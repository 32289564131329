import React, { useState } from 'react'
import { FieldProps as FieldFormikProps } from 'formik'
import { FormGroup, InputProps, FormText, Label } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/fr'

import * as firebase from 'firebase/app'
import 'firebase/firestore'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../assets/css/react-dates.css'

import { SingleDatePicker } from 'react-dates'

import Field from '../Field'

moment.locale('fr')





interface FieldProps extends FieldFormikProps, InputProps {
	label?: string,
	helpText?: string,
	name: string,
	form: any,
	isOutsideRange: any,
	time?: string
}

const DatePickerFieldComponent: React.FC<FieldProps> = ({
	field,
	form: {touched, errors, setFieldValue},
	...props
}) => {
	const [focused, setFocused] = useState(false)


	const setDateValue = (date: any) => {
		let timestamp: any

		if (props.time) {
			const hours = props.time.split(':')[0]
			const minutes  = props.time.split(':')[1]
			timestamp = firebase.firestore.Timestamp.fromDate(date.hours(hours).minutes(minutes).toDate())
		} else {
			timestamp = firebase.firestore.Timestamp.fromDate(date.toDate())
		}
		setFieldValue(field.name, timestamp)
	}

	return (
		<FormGroup>
			{props.label && <Label for={props.id}>{props.label} {props.required && "*"}</Label>}
			<SingleDatePicker
				numberOfMonths={1}
				date={field.value ? moment.unix(field.value.seconds) : null} // momentPropTypes.momentObj or null
				onDateChange={(date: any) => setDateValue(date)} // PropTypes.func.isRequired
				focused={focused} // PropTypes.bool
				onFocusChange={({ focused }) => focused !== null && setFocused(focused)} // PropTypes.func.isRequired
				id={field.name} // PropTypes.string.isRequired,
				placeholder={props.placeholder}
				isOutsideRange={props.isOutsideRange}
			/>
			{touched[field.name] && errors[field.name] && <FormText color="error" className="invalid-feedback">{errors[field.name]}</FormText>}
			{props.helpText && <FormText>{props.helpText}</FormText>}
		</FormGroup>
	)
}

const DatePickerField = (props: any) => <Field component={DatePickerFieldComponent} {...props} />

export default DatePickerField