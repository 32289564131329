import React from 'react'

import { Form, TextField } from 'loxamtraining-form'

interface EditProps {
	data: any,
	isLoading?: boolean,
	handleSubmit: any,
	hideSubmitButton?: boolean
}

const Edit: React.FC<EditProps> = props => (
	props.isLoading ? (<p>Chargement en cours...</p>) : (
		<Form onSubmit={props.handleSubmit} initialValues={props.data} hideSubmitButton={props.hideSubmitButton}>
			{props.children}
		</Form>
	)
)

Edit.defaultProps = {
	isLoading: false
}

export default Edit