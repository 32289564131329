import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Alert } from 'reactstrap'
import queryString from 'query-string'
import { Form, PasswordField, EmailField } from 'loxamtraining-form'


import { Loading } from '../'

import defaultLogo from '../assets/images/brand-logo.svg'


interface ResetPasswordProps {
	handleVerifyPasswordCode:any,
	handleResetPassword: any,
	logo?: any
}


const ResetPassword: React.FC<ResetPasswordProps> = ({handleResetPassword, handleVerifyPasswordCode, logo}) => {
	const history = useHistory()
	let location = useLocation()

	const oobCode = queryString.parse(location.search).oobCode

	const [isLoading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [submitSucceeded, setSubmitSucceeded] = useState(false)
	
	useEffect(() => {
		//Check the oobCode
		if ( oobCode ) {
			handleVerifyPasswordCode(oobCode).then(() => {
				setLoading(false)
			}).catch((error:any) => {
				setError(error.message)
				setLoading(false)
			})
		}
	}, [])

	const handleSubmit = (values: any, actions: any) => {
		handleResetPassword(values.code, values.password).then(() => {
			setSubmitSucceeded(true)
			actions.setSubmitting(false)
		}).catch((error: any) => {
			error && error.message ? (
				actions.setStatus(error.message)
			) : (
				actions.setStatus("Erreur lors de la demande de réinitialisation de mot de passe")
			)
			
			actions.setSubmitting(false)
		})
	}

	//Check the password and confirmPassword are identic
	const validate = (values: any) => {
		const errors: any = {}

		if (values.password !== values.confirmPassword) {
			errors.confirmPassword = "La confirmation du mot de passe n'est pas identidentique au mot de passe"
		}

		return errors
	}

	return (
		isLoading ? (<Loading />) : (
			<main className="auth">
				<header id="auth-header" className="auth-header" >
					<h1>
						<img src={logo ? logo : defaultLogo} height="28" alt="" />
						<span className="sr-only">Sign In</span>
					</h1>
				</header>
				<div className="auth-form">
					{
						error ? (
							<Alert color="danger">
								<p>{error}</p>
								<p><Link to="/forgot-password">Cliquez ici pour demander une nouvelle réinitialisation.</Link></p>
							</Alert>
						) : (
							submitSucceeded ? (
								<Alert color="success">
									<p>Merci le mot de passe à bien été mise à jour.</p>
									<p><Link to="/login">Se connecter</Link></p>
								</Alert>
							) : (
								<React.Fragment>
									<p className="text-center">
										Merci de renseigner un nouveau mot de passe.
									</p>
									<Form validate={validate} onSubmit={handleSubmit} initialValues={{password: "", confirmPassword: "", code: oobCode}} buttonClassName="btn btn-lg btn-primary btn-block">
										<PasswordField name="password" placeholder="Nouveau mot de passe" required />
										<PasswordField name="confirmPassword" placeholder="Confirmation du nouveau mot de passe" required />
									</Form>
								</React.Fragment>
							)
						)
					}
					
				</div>
			</main>
		)
	)
}

export default ResetPassword