import React from 'react'

interface TableProps {
	data: any,
	isLoading?: any
}


const Table: React.FC<TableProps> = (props) => (
	<div className="table-responsive">
		<table className="table">
			<thead>
				<tr>
					{
						React.Children.map(props.children, (child: any) => (
							<th style={child.props.action && {width:"100px", minWidth:"100px"}}>{child.props.label ? child.props.label : " "}</th>
						))
					}
				</tr>
			</thead>
			<tbody>
				{
					!props.isLoading ? props.data.length > 0 ? 
						props.data.map((item: any, index: number) => (
							<tr key={index}>
								{
									React.Children.map(props.children, (child: any, index: number) => (
										<td key={index} className={`align-middle ${child.props.action && "text-right"}`}>
											{React.cloneElement(child, {data: child.props.name ? item[child.props.name] : item})}
										</td>
									))
								}
							</tr>
						)) : (
							<tr>
								<td colSpan={React.Children.count(props.children)}>Pas encore d'élément</td>
							</tr>
						) : (
							<tr>
								<td colSpan={React.Children.count(props.children)}>Chargement en cours...</td>
							</tr>
						)
				}
			</tbody>
		</table>
	</div>
)


Table.defaultProps = {
	isLoading: false
}


export default Table