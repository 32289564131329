import React from 'react'
import Select from 'react-select'
import { FieldProps as FieldFormikProps} from 'formik'
import { FormGroup, InputProps, FormText, Label } from 'reactstrap'
import Field from '../Field'


interface FieldProps extends FieldFormikProps, InputProps {
	label?: string,
	helpText?: string,
	name: string,
	form: any,
	isMulti?: boolean,
	options: any,
  isLoading?: boolean
}

const ChoiceFieldComponent: React.FC<FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  isMulti = false,
  options,
  isLoading,
  ...props
}) => {
	const onChange = (options: any) => {
		setFieldValue(
      		field.name,
      		isMulti
        		? options.map((item: any) => item.value)
        		: options.value
    	)
  	}

  	const getValue = () => {
    	if (options) {
      		return isMulti
        		? options.filter((option: any) => field.value && field.value.indexOf(option.value) >= 0)
        		: options.find((option: any) => option.value === field.value);
    	} else {
      		return isMulti ? [] : ("" as any);
    	}
  	}

	return (
	 	<FormGroup>
	 		{props.label && <Label for={props.id}>{props.label} {props.required && "*"}</Label>}
	  		<Select isLoading={isLoading} options={options} value={getValue()} onChange={onChange} isMulti={isMulti} placeholder={props.placeholder} />
	  		{touched[field.name] && errors[field.name] && <FormText color="error" className="invalid-feedback">{errors[field.name]}</FormText>}
			  {props.helpText && <FormText>{props.helpText}</FormText>}
	  	</FormGroup>
	)
}

const ChoiceField = (props:any) => <Field component={ChoiceFieldComponent} {...props} />


export default ChoiceField