import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { FirestoreClient } from 'loxamtraining-core'
import { Page, PageTitleBar, PageSection, Edit } from '../partials'

interface PageEditProps {
	title: string,
	path: string
}

const PageEdit: React.FC<PageEditProps> = props => {
	const firestoreClient = new FirestoreClient()
	const [isLoading, setLoading] = useState(true)
	const [data, setData] = useState({})

	//Get the id
	const { id } = useParams() 

	useEffect(() => {
		if (id) {
			firestoreClient.get(`${props.path}/${id}`).then((data) => {
				setData(data)
				setLoading(false)
			})
		}
	}, [])

	const handleSubmit = (values: any, actions: any) => {
		firestoreClient.update(`${props.path}/${id}`, values).then((data) => {
			setData(data)
			actions.setSubmitting(false)
		})
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">{props.title}</h1>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Edit handleSubmit={handleSubmit} data={data} isLoading={isLoading}>
							{props.children}
						</Edit>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}

export default PageEdit