import React, { useState, useEffect } from 'react'
import * as firebase from 'firebase/app'
import {BrowserRouter, Switch, Route, Redirect, RouteProps, Link, useHistory} from 'react-router-dom'
import { DropdownItem } from 'reactstrap'
import { PrivateRoute, AuthClient, UserContext } from 'loxamtraining-core'

import settings from './settings'

import { LevelMenu, User, DefaultLayout, Login, Loading, ForgotPassword, ResetPassword } from '../lib/apptheme'

import { 
    Settings,
    TrainingCenterList, 
    TrainingCenterEdit, 
    TrainingCenterAdd, 
    TrainingOrganizationList, 
    TrainingOrganizationEdit, 
    TrainingOrganizationAdd,
    BookingList,
    BookingEdit,
    BookingAdd,
    BookingCalendar,
    OrderList,
    PlatformList,
    PlatformEdit,
    PlatformAdd
} from './pages'

import logo from './assets/images/loxam-logo-white-default.svg'


const firebaseConfig = settings.firebaseConfig


// Initialize Firebase
firebase.initializeApp(firebaseConfig)


const asideMenu: LevelMenu[] = [
	// {
	// 	id: "dashboard",
	// 	type: "link",
	// 	text: "Tableau de bord",
	// 	icon: "fas fa-tachometer-alt",
	// 	link: "/"
 //    },
    {
        id: "planning",
        type: "link",
        text: "Planning",
        icon: "fas fa-calendar-alt",
        link: "/"
    },
    {
        id: "bookings",
        type: "link",
        text: "Réservations",
        icon: "fas fa-list",
        link: "/bookings"
    },
    {
        id: "trainingorganizations",
        type: "link",
        text: "Organismes de formation",
        icon: "fas fa-user-friends",
        link: "/trainingorganizations"
    },
    {
        id: "platform",
        type: "link",
        text: "Plateaux technique",
        icon: "fas fa-chess-rook",
        link: "/platforms"
    },
    {
        id: "trainingcenter",
        type: "link",
        text: "Sites de formation",
        icon: "fas fa-chess-board",
        link: "/trainingcenters"
    },
    {
        id: "orders",
        type: "link",
        text: "Commandes",
        icon: "fas fa-inbox",
        link: "/orders"
    }
]

const DropDownItems = () => {
    const history = useHistory()
    return (
        <React.Fragment>
            <DropdownItem onClick={() => history.push('/settings')}>
                <span className="dropdown-icon oi oi-cog"></span> Paramètres
            </DropdownItem>
        </React.Fragment>
    )
}


const App: React.FC = () => {
    const [isLoading, setLoading]: [boolean, any]= useState(true)
    const [user, setUser]: [any, any] = useState(null)


    const authClient = new AuthClient()

    useEffect(() => {
        //Get the current user
        authClient.onCurrentUser((user: any) => {
            setUser(user)
            setLoading(false)
        })
    }, [])


    const handleLogin = (email: string, password: string) => {
        //Login the user
        return authClient.login(email, password).then((userCredential: any) => {
            //Get user info and set set user before redirection
            return authClient.getUserInfo(userCredential.user.uid).then((user: any) => {
                return setUser(user)
            })    
        })
    }

    const handleForgotPassword = (email: string) => {
        return authClient.sendPasswordResetEmail(email)
    }

    const handleResetPassword = (code:string, password: string) => {
        return authClient.resetPassword(code, password)
    }

    const handleVerifyPasswordCode = (code:string) => {
        return authClient.verifyPasswordResetCode(code)
    }

    let userProfil: User = {
        name: user && user.firstName
    }

    return (
        isLoading ? (
            <Loading />
        ) : (
            <BrowserRouter>
                <UserContext.Provider value={user}>
                    <Switch>
                        <Route exact path="/login">
                            <Login handleLogin={handleLogin} logo={logo} />
                        </Route>
                        <Route exact path="/forgot-password">
                            <ForgotPassword handleForgotPassword={handleForgotPassword} logo={logo} />
                        </Route>
                         <Route exact path="/reset-password">
                            <ResetPassword handleResetPassword={handleResetPassword} handleVerifyPasswordCode={handleVerifyPasswordCode} logo={logo} />
                        </Route>
                        <PrivateRoute path="/" isAuthenticated={user ? true : false}>
                            <DefaultLayout asideMenu={asideMenu} user={userProfil} logo={logo} userDropdownItems={<DropDownItems />}>
                                <Switch>
                                    <Route exact path="/" component={BookingCalendar} />
                                    <Route exact path="/bookings" component={BookingList} />
                                    <Route exact path="/bookings/add" component={BookingAdd} />
                                    <Route exact path="/bookings/:id" component={BookingEdit} />
                                    <Route exact path="/trainingcenters" component={TrainingCenterList} />
                                    <Route exact path="/trainingcenters/add" component={TrainingCenterAdd} />
                                    <Route exact path="/trainingcenters/:id" component={TrainingCenterEdit} />
                                    <Route exact path="/trainingorganizations" component={TrainingOrganizationList} />
                                    <Route exact path="/trainingorganizations/add" component={TrainingOrganizationAdd} />
                                    <Route exact path="/trainingorganizations/:id" component={TrainingOrganizationEdit} />
                                    <Route exact path="/orders" component={OrderList} />
                                    <Route exact path="/platforms" component={PlatformList} />
                                    <Route exact path="/platforms/add" component={PlatformAdd} />
                                    <Route exact path="/platforms/:id" component={PlatformEdit} />
                                    <Route path="/settings" component={Settings} />
                                </Switch>
                            </DefaultLayout>
                        </PrivateRoute>
                    </Switch>
                </UserContext.Provider>
            </BrowserRouter>
        )
  )
}

export default App
