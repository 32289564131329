import React from 'react'
import { Row, Col } from 'reactstrap'
import { useAsyncOptions, useUserContext } from 'loxamtraining-core'
import { Form, TextField, EmailField, SelectField, ChoiceField } from 'loxamtraining-form'
import { TextData, EditData } from 'loxamtraining-data'

import { PageList, PageEdit, PageAdd } from '../../lib/apptheme'

export const TrainingOrganizationList = () => {
	const user: any | null = useUserContext()

	const addButton = (user && (user.roles.admin || user.roles.agent)) ? (
							{name: "Ajouter un organisme de formation", path: "/trainingorganizations/add"}
						) : undefined

	const filterData = (user.roles.agent && user.roles.admin === false) ? ((data: any) => data.filter((item: any) => user.stores.indexOf(item.storeId) > -1)) : undefined


	return (
		<PageList path="/trainingorganizations" title="Organisme de formation" addButton={addButton} filterData={filterData}>
			<TextData name="storeId" label="Site de formation" refPath="/stores" keyName="name" />
			<TextData name="customerCode" label="Code RTM" />
			<TextData name="company" label="Entreprise" />
			<TextData name="firstName" label="Prénom" />
			<TextData name="lastName" label="Nom" />
			<TextData name="phone" label="Téléphone" />
			<TextData name="email" label="Email" />
			<EditData path="/trainingorganizations" name="id" />
		</PageList>
	)
}


const TrainingOrganizationForm = () => {
	let [isLoading, options] = useAsyncOptions('/stores', 'id', 'name')

	const user: any | null = useUserContext()

	//Filter on store id base on the use roles
	if (options && user.roles.agent && user.roles.admin === false){
		options = options.filter((item: any) => user.stores.indexOf(item.value) > -1)
	}

	return (
		<React.Fragment>
			<fieldset>
				<ChoiceField name="storeId" label="Site de formation" options={options} isLoading={isLoading} />
			</fieldset>
			<fieldset className="mb-2 mt-3">
				<legend>Informations</legend>
					<Row>
						<Col md="4">
							<TextField label="Entreprise" name="company" required />
						</Col>
						<Col md="4">
							<TextField label="Forme Juridique" name="companyLegalForm" required />
						</Col>
						<Col md="4">
							<TextField label="Siret" name="companyId" required />
						</Col>
					</Row>
					<Row>
						<Col md="4">
							<TextField label="Prénom" name="firstName" required />
						</Col>
						<Col md="4">
							<TextField label="Nom" name="lastName" required />
						</Col>
						<Col md="4">
							<TextField label="Fonction" name="office" required />
						</Col>
					</Row>
					<Row>
						<Col md="6">
							<EmailField label="Email" name="email" required />
						</Col>
						<Col md="6">
							<TextField label="Téléphone" name="phone" required />
						</Col>
					</Row>
			</fieldset>
			<fieldset className="mb-2 mt-3">
				<legend>Adresse de l'organisme de formation</legend>
				<TextField label="Adresse" name="address.address1" required />
				<TextField label="Complément d'adresse" name="address.address2" />
				<Row>
					<Col lg="6">
						<TextField label="Code Postal" name="address.zipcode" required />
					</Col>
					<Col lg="6">
						<TextField label="Ville" name="address.city" required />
					</Col>
				</Row>
				<SelectField name="address.country" label="Pays" required>
					<option value="">---</option>
					<option value="FR">France</option>
				</SelectField>
			</fieldset>
			<fieldset className="mb-2 mt-3">
				<legend>RentalMAN</legend>
				<TextField name="customerCode" label="Code client RTM" />
			</fieldset>
		</React.Fragment>
	)
}


export const TrainingOrganizationAdd = () => (
	<PageAdd path="/trainingorganizations" title="Nouvel organisme de formation">
		<TrainingOrganizationForm />
	</PageAdd>
)


export const TrainingOrganizationEdit = () => {

	return (
		<PageEdit path="/trainingorganizations" title="Détail organisme de formation">
			<TrainingOrganizationForm />
		</PageEdit>
	)
}