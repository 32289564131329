import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Link, useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useAsyncData, FirestoreClient, useAsyncOptions, useUserContext, useAsyncData2 } from 'loxamtraining-core'
import { Form, ChoiceField, DatePickerField, TextAreaField } from 'loxamtraining-form'
import { TextData, UnixDateData, EditData } from 'loxamtraining-data'
import { DayGridCalendar } from 'loxamtraining-calendar'


import { Page, PageSection, PageTitleBar, Table, Edit } from '../../lib/apptheme'
import { getStoreFilteredData } from '../utils'

const platformLabels: any = {
	'R482': 'R482 / Engins de chantier Terrassement',
	'R486': 'R486 / Nacelle',
	'R489': 'R489 / Chariots',
	'R484': 'R484 / Pont roulant',
	'R485': 'R485 / Transpalette gerbeur accompagnant',
	'echafaudageroulant': 'Echafaudage Roulant',
	'travailhauteur': 'Travail en hauteur'
}


export const Booking_old = () => (
	<div className="page has-sidebar has-sidebar-fixed has-sidebar-expand-xl">
		<div className="page-inner page-inner-fill">
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Réservations</h1> 
			</PageTitleBar>
			<div className="board">
				<PageSection>
					<Card>
						<DayGridCalendar />
					</Card>
				</PageSection>
			</div>
		</div>

		<div className="page-sidebar page-sidebar-fixed">
			<div className="sidebar-section-fill">
				<div id="calendar-list">
					<div className="fc-view-container"><div className="fc-view fc-listMonth-view fc-list-view card card-reflow"><div className="fc-scroller" style={{overflow: "hidden auto", height: "auto"}}><div className="fc-list-empty-wrap2"><div className="fc-list-empty-wrap1"><div className="fc-list-empty">No events to display</div></div></div></div></div></div>
				</div>
			</div>
		</div>
	</div>
)

const BookingFields = () => {
	const user: any | null = useUserContext()
	
	const filterData = (user.roles.agent && user.roles.admin === false)  ? (data: any) => data.filter((item: any) => user.stores.indexOf(item.storeId) > -1) : undefined
	
	let [isLoadingStoreOptions, storeOptions] = useAsyncOptions('/stores', 'id', 'name')
	let [isLoadingToOptions, toOptions] =  useAsyncOptions('/trainingorganizations', 'id', 'company', filterData)
	let [isLoadingPlatformOptions, platformOptions] = useAsyncOptions('/platforms', 'id', 'type',filterData)

	//Filter on store id base on the use roles
	if (storeOptions && user.roles.agent && user.roles.admin === false){
		storeOptions = storeOptions.filter((item: any) => user.stores.indexOf(item.value) > -1)
	}

	//Create label options for platformOptions
	platformOptions = platformOptions.map((platformOption: any) => {
		return {label: platformLabels[platformOption.label], value: platformOption.value}
	})

	return (
		<React.Fragment>
			<ChoiceField name="storeId" label="Site de formation" options={storeOptions} isLoading={isLoadingStoreOptions} required />
			<ChoiceField name="trainingorganizationId" label="Organisme de formation" options={toOptions} isLoading={isLoadingToOptions} required />
			<ChoiceField name="platforms" label="Plateformes" options={platformOptions} isLoading={isLoadingPlatformOptions} isMulti required />
			<DatePickerField name="startDate" label="Date de début" required time="08:00" />
			<DatePickerField name="endDate" label="Date de fin" required time="18:00" />
			<TextAreaField name="comment" label="Commentaire" helptext="Par exemple lister les équipements demandés pour la formation" />
		</React.Fragment>
	)
}


const PlatformsData = (props: any) => {
	const path = `/platforms`
	const [isLoading, data] = useAsyncData(path)

	let platforms: any[] = []

	if (data.length > 0) {
		platforms = props.data.map((item: any) => {
			const platform = data.filter((platform: any) => platform.id === item)[0]

			return `${platformLabels[platform.type]} : ${platform.trainingCategories.join(', ')}`
		})
	}

	return (
		<React.Fragment>
			{
				props.isLoading ? "loading..." : data ? platforms.map((platform, index) => (<React.Fragment key={index}>{platform}<br /></React.Fragment>)) : "-"
			}
		</React.Fragment>
	)
}


export const BookingCalendar = () => {
	const firestoreClient = new FirestoreClient()
	const [isLoading, setLoading]: [boolean, any] = useState(true)
	const [data, setData]: [any, any] = useState(null)
	const [eventsList, setEventsList]: [any, any] = useState([])

	const history = useHistory()

	useEffect(() => {

		if (data === null) {
			firestoreClient.getAll('/bookings').then((data) => {
				setData(data)
			})
		}

		if (data) {
			//Create training organization list with unique id
			const trainingOrganizations: any[] = data.map((item: any) => item.trainingorganizationId)
														.filter((item: any, index: number, array: any) => array.indexOf(item) === index)


			//Create an event for platform booked
			let events = data.reduce((acc: any, item: any) => {
				acc = acc.concat([{
					start: moment.unix(item.startDate.seconds).format(),
					end: moment.unix(item.endDate.seconds).add(1, 'day').format(),
					rendering: 'background',
					color: "#fb263b",
					allDay: true
				}])


				acc = acc.concat([{
					title: item.trainingorganizationId,
					start: moment.unix(item.startDate.seconds).format(),
					end: moment.unix(item.endDate.seconds).add(1, 'day').format(),
					allDay: true,
					extendedProps: {
						platforms: item.platforms,
						bookingId: item.id
					}
				}])

				return acc
			}, [])


			//Map training information
			if (trainingOrganizations.length > 0) {
				const promises = trainingOrganizations.map(trainingOrganization => {
					return firestoreClient.get(`trainingorganizations/${trainingOrganization}`)
				})

				Promise.all(promises).then(results => {
					events = events.map((event: any) => {
						if (event.title) {
							const company = results.filter(result => result.id === event.title)[0].company
							const platforms = event.extendedProps.platforms.join('\n')
							const title = `Id : ${event.extendedProps.bookingId}\nOF : ${company}\n\nPlatformes :\n${platforms}`
							return Object.assign(event, {title: title})
						} else {
							return event
						}
						
					})

					setEventsList(events)
					setLoading(false)
				})
			}
		}
	}, [data])
	

	const settings = {
		events: eventsList,
		eventClick: (info: any) => {
			history.push(`/bookings/${info.event.extendedProps.bookingId}`)
		}
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Planning</h1> 
			</PageTitleBar>
			<PageSection>
				Pas encore d'élément
			</PageSection>
		</Page>
	)
}


export const BookingList = () => {
	const [isLoading, data] = useAsyncData('/bookings')

	const user: any | null = useUserContext()

	const filteredData: any[] = getStoreFilteredData(data, user, 'storeId')

	return (
		<Page>
			<PageTitleBar>
				<div className="d-flex justify-content-between">
					<h1 className="page-title mr-sm-auto">Réservations</h1>
					<div className="btn-toolbar">
						<Link to="/bookings/add" className="btn btn-secondary"><i className="fa fa-plus"></i> Ajouter une réservation</Link>
					</div>
				</div>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Table data={filteredData} isLoading={isLoading}>
							<TextData name="storeId" label="Site de formation" refPath="/stores" keyName="name" />
							<TextData name="trainingorganizationId" label="Organisme de formation" refPath='/trainingorganizations' keyName="company" />
							<PlatformsData name="platforms" label="Plateformes" />
							<UnixDateData name="startDate" label="Date de début" />
							<UnixDateData name="endDate" label="Date de fin" />
							<EditData path="/bookings" name="id" />
						</Table>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}


export const BookingAdd = () => {
	const firestoreClient = new FirestoreClient()
	const history = useHistory()

	const handleSumit = (values: any, actions: any) => {
		firestoreClient.add('/bookings', values).then((id) => {
			history.push(`/bookings/${id}`)
		})		
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Nouvelle réservation</h1> 
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Form onSubmit={handleSumit} initialValues={{}}>
							<BookingFields />
						</Form>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}



export const BookingEdit = () => {
	const firestoreClient = new FirestoreClient()
	const [isLoading, setLoading] = useState(true)
	const [data, setData] = useState({})
	
	//Get the id
	const { id } = useParams() 

	useEffect(() => {
		if (id) {
			firestoreClient.get(`/bookings/${id}`).then((data) => {
				setData(data)
				setLoading(false)
			})
		}
	}, [])

	const handleSubmit = (values: any, actions: any) => {
		firestoreClient.update(`/bookings/${id}`, values).then((data) => {
			setData(data)
			actions.setSubmitting(false)
		})
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Détail site de formation</h1>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Edit handleSubmit={handleSubmit} data={data} isLoading={isLoading}>
							<BookingFields />
						</Edit>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}


