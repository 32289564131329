import { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { FirestoreClient, UserContext } from './'

export const useAsyncOptions = (path: string, keyValue: string, keyLabel: string, filterData?: (data: any[]) => any[]) => {
	const firestoreClient = new FirestoreClient()

	const [isLoading, setLoading] = useState(true)
	const [options, setOptions]: [any, any] = useState([])

	useEffect(() => {
		firestoreClient.getAll(path).then((data) => {
			let options: any
			if (filterData) {
				options = filterData(data).map((item: any) => ({value: item[keyValue], label: item[keyLabel]}))
			} else {
				options = data.map((item: any) => ({value: item[keyValue], label: item[keyLabel]}))
			}
			setOptions(options)
			setLoading(false)
		})
	}, [])

	return [isLoading, options]
}

export const useAsyncData = (path: string) => {
	const location = useLocation()
	const firestoreClient = new FirestoreClient()
	const [isLoading, setLoading]: [boolean, any] = useState(true)
	const [data, setData]: [any, any] = useState([])

	let currentLocation: string
	
	useEffect(() => {
		if (currentLocation === undefined || currentLocation === location.pathname) {
			firestoreClient.getAll(path).then((data) => {
				setData(data)
				setLoading(false)
			})
		}

		currentLocation = location.pathname
	}, [location])


	return  [isLoading, data]
}


export const useAsyncData2 = (path?: string) => {
	const firestoreClient = new FirestoreClient()
	const [isLoading, setLoading]: [boolean, any] = useState(true)
	const [data, setData]: [any, any] = useState([])
	

	useEffect(() => {
		if (path) {
			firestoreClient.get(path).then((data) => {
				setData(data)
				setLoading(false)
			})
		}
	}, [])


	return  [isLoading, data, setData, setLoading]
}


export const useModal = () => {
	const [modal, setModal]: [boolean, any] = useState(true)
	const history = useHistory()


	const toggle: () => void = () => {
		setModal(false)

		setTimeout(() => {
			history.goBack()
		}, 300)
	}

	return [modal, setModal, toggle]
}

export const useUserContext = () => {
	const user = useContext(UserContext)

	return user
}