import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'

import '@fullcalendar/core/main.css'

import looperCalendarTheme from './CalendarTheme'

const events = [
  { resourceId: 'a', title: '', start: '2020-02-03T12:00:00+00:00', end: '2020-02-05T08:00:00+00:00' },
]

const defaultSettings = {
  header: {
    left: 'title',
    center: '',
    right: 'prev next today',
  },
  height: 'auto',
  contentHeight: '100%',
  plugins: [ dayGridPlugin, looperCalendarTheme ],
  themeSystem: 'looper',
  defaultView: 'dayGridMonth',
  locale: frLocale,
  events: events,
  weekends: false,
  eventBackgroundColor: 'transparent',
  eventBorderColor: '#fb263b'
}

interface DayGridCalendarProps {
  settings?: any
}

const DayGridCalendar: React.FC<DayGridCalendarProps> = ({ settings }) => {
    return <FullCalendar {...Object.assign(defaultSettings, settings)} />
}

export default DayGridCalendar