/**
 *	Filter on storeId base on the roles of the user
 */
export const getStoreFilteredData  = (data: any, user: any, key: string) => {
	if (user !== null) {
		if (user.roles.admin) {
			return  data
		}
		else if (user.roles.agent) { 
			return data.filter((item: any) => user.stores.indexOf(item[key]) > -1)
		}
		else {
			return []
		}
	} else {
		return []
	}
}