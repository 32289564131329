import React, { useEffect, useState , useContext } from 'react'
import {Card, CardBody, Row, Col, Button } from 'reactstrap'
import { Link, useParams, useHistory } from 'react-router-dom'
import { FieldArray } from 'formik'
import { FirestoreClient, useUserContext } from 'loxamtraining-core'
import { Form, TextField, SelectField, ChoiceField, TextAreaField } from 'loxamtraining-form'
import { TextData, AddressData, EditData } from 'loxamtraining-data'


import { Page, PageSection, Table, PageTitleBar, Edit } from '../../lib/apptheme'

import { getStoreFilteredData } from '../utils'

const platforms = [
  { value: 'R482', label: 'R482 / Engins de chantier Terrassement' },
  { value: 'R486', label: 'R486 / Nacelle' },
  { value: 'R489', label: 'R489 / Chariots' },
  { value: 'R484', label: 'R484 / Pont roulant' },
  { value: 'R485', label: 'R485 / Transpalette gerbeur accompagnant' }
]

interface dayChoiceData {
	value: number,
	label: string
}

interface hourChoiceData {
	value: string,
	label: string
}


const dayChoices: dayChoiceData[] = [
	{value: 0, label: "Dimanche"},
	{value: 1, label: "Lundi"},
	{value: 2, label: "Mardi"},
	{value: 3, label: "Mercredi"},
	{value: 4, label: "Jeudi"},
	{value: 5, label: "Vendredi"},
	{value: 6, label: "Samedi"}
]

const hourChoices: hourChoiceData[] = [
	{value: "0000", label: "00:00"},
	{value: "0030", label: "00:30"},
	{value: "0100", label: "01:00"},
	{value: "0130", label: "01:30"},
	{value: "0200", label: "02:00"},
	{value: "0230", label: "02:30"},
	{value: "0300", label: "03:00"},
	{value: "0330", label: "03:30"},
	{value: "0400", label: "04:00"},
	{value: "0430", label: "04:30"},
	{value: "0500", label: "05:00"},
	{value: "0530", label: "05:30"},
	{value: "0600", label: "06:00"},
	{value: "0630", label: "06:30"},
	{value: "0700", label: "07:00"},
	{value: "0730", label: "07:30"},
	{value: "0800", label: "08:00"},
	{value: "0830", label: "08:30"},
	{value: "0900", label: "09:00"},
	{value: "0930", label: "09:30"},
	{value: "1000", label: "10:00"},
	{value: "1030", label: "10:30"},
	{value: "1100", label: "11:00"},
	{value: "1130", label: "11:30"},
	{value: "1200", label: "12:00"},
	{value: "1230", label: "12:30"},
	{value: "1300", label: "13:00"},
	{value: "1330", label: "13:30"},
	{value: "1400", label: "14:00"},
	{value: "1430", label: "14:30"},
	{value: "1500", label: "15:00"},
	{value: "1530", label: "15:30"},
	{value: "1600", label: "16:00"},
	{value: "1630", label: "16:30"},
	{value: "1700", label: "17:00"},
	{value: "1730", label: "17:30"},
	{value: "1800", label: "18:00"},
	{value: "1830", label: "18:30"},
	{value: "1900", label: "19:00"},
	{value: "1930", label: "19:30"},
	{value: "2000", label: "20:00"},
	{value: "2030", label: "20:30"},
	{value: "2100", label: "21:00"},
	{value: "2130", label: "21:30"},
	{value: "2200", label: "22:00"},
	{value: "2230", label: "22:30"},
	{value: "2300", label: "23:00"},
	{value: "2330", label: "23:30"}
]


const TrainingCenterFields = () => (
	<React.Fragment>
		<fieldset>
			<TextField name="name" label="Nom du site de formation" required />
		</fieldset><fieldset>
			<legend className="mb-1 mbt-2">Adresse du site de formation</legend>
			<TextField label="Adresse" name="address.address1" required />
			<TextField label="Complément d'adresse" name="address.address2" />
			<Row>
				<Col lg="6">
					<TextField label="Code Postal" name="address.zipcode" required />
				</Col>
				<Col lg="6">
					<TextField label="Ville" name="address.city" required />
				</Col>
			</Row>
			<SelectField name="address.country" label="Pays" required>
				<option>----</option>
				<option value="FR">France</option>
			</SelectField>
		</fieldset>
		<fieldset>
			<legend className="mb-1 mbt-2">Contact</legend>
			<TextField name="phone" label="Téléphone" required />
		</fieldset>
		<fieldset>
			<legend className="mb-1 mbt-2">Horaire d'ouverture</legend>
			<FieldArray name="openHours">
				{
					({ form, remove, push }) => {

						const handleRemove = (index: number) => {
							remove(index)
						}

						const handlePush = () => {
							push({
								openDays: [],
								openHour: "",
								closeHour: ""
							})
						}

						return (
							<div className="table-responsive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th>Jours d'ouvertures</th>
											<th>Heure d'ouverture</th>
											<th>Heure de fermeture</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{
											form.values.openHours && form.values.openHours.map((item: any, index: number) => (
												<tr key={index}>
													<td><ChoiceField name={`openHours[${index}].openDays`} options={dayChoices} isMulti /></td>
													<td><ChoiceField name={`openHours[${index}].openHour`} options={hourChoices} /></td>
													<td><ChoiceField name={`openHours[${index}].closeHour`} options={hourChoices} /></td>
													<td className="align-middle text-right">
														<button onClick={() => handleRemove(index)} className="btn btn-sm btn-icon btn-secondary">
															<i className="fa fa-trash"></i>
																<span className="sr-only">Delete</span>
															</button>
													</td>
												</tr>
											))
										}
									</tbody>
								</table>
								<p className="text-right">
									<Button onClick={() => handlePush()} color="link"><i className="fa fa-plus"></i> Ajouter des horaires</Button>
								</p>
							</div>
						)
					}
				}
			</FieldArray>
			<TextAreaField name="openDescription" label="Description des horaires" />
		</fieldset>
		<fieldset>
			<legend className="mb-1 mbt-2">Caractéristiques</legend>
			<SelectField name="nbTrainingRoom" label="Nombre de salle de formation" required>
				<option>---</option>
				{
					(new Array(10)).fill(undefined).map((value: any, index: number) => {
						return <option value={index + 1} key={index}>{index + 1}</option>
					})
				}
			</SelectField>
		</fieldset>
		
	</React.Fragment>
)


export const TrainingCenterList = () => {
	const firestoreClient = new FirestoreClient()
	const [isLoading, setLoading] = useState(true)
	const [data, setData] = useState([])	
	useEffect(() => {
		firestoreClient.getAll('/stores').then((stores) => {
			setData(stores)
			setLoading(false)
		})
	}, [])


	//Add filter access to the data base on roles of the user
	const user: any | null = useUserContext()

	const filteredData: any[] = getStoreFilteredData(data, user, 'id')

	return (
		<Page>
			<PageTitleBar>
				<div className="d-flex justify-content-between">
					<h1 className="page-title mr-sm-auto">Site de formation</h1>
					{
						user.roles.admin && (
							<div className="btn-toolbar">
								<Link to="/trainingcenters/add" className="btn btn-secondary"><i className="fa fa-plus"></i> Ajouter un site de formation</Link>
							</div>
						)
					}
				</div>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Table data={filteredData} isLoading={isLoading}>
							<TextData name="name" label="Nom du site de formation" />
							<AddressData name="address" label="Adresse" />
							<TextData name="phone" label="Téléphone" />
							<EditData path="/trainingcenters" name="id" />
						</Table>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}

export const TrainingCenterAdd = () => {
	const firestoreClient = new FirestoreClient()
	const history = useHistory()

	const handleSubmit = (values: any, actions: any) => {
		console.log(values)
		firestoreClient.add('/stores', values).then((id) => {
			history.push(`/trainingcenters/${id}`)
		}).catch(error => {
			console.log(error)
		})
	}

	const initialValues = {
		name: '',
		address: {
			address1: '',
			address2: '',
			zipcode: '',
			city: '',
			country: ''
		},
		phone: '',
		openHours: [],
		openDescription: '',
		nbTrainingRoom: ''
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Nouveau site de formation</h1> 
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Form onSubmit={handleSubmit} initialValues={initialValues}>
							<TrainingCenterFields />
						</Form>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}

export const TrainingCenterEdit = () => {
	const firestoreClient = new FirestoreClient()
	const [isLoading, setLoading] = useState(true)
	const [data, setData] = useState({})

	//Get the id
	const { id } = useParams() 

	useEffect(() => {
		if (id) {
			firestoreClient.get(`/stores/${id}`).then((store) => {
				setData(store)
				setLoading(false)
			})
		}
	}, [])

	const handleSubmit = (values: any, actions: any) => {
		firestoreClient.update(`/stores/${id}`, values).then((data) => {
			setData(data)
			actions.setSubmitting(false)
		})
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">Détail site de formation</h1>
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Edit handleSubmit={handleSubmit} data={data} isLoading={isLoading}>
							<TrainingCenterFields />
						</Edit>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}