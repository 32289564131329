import React from 'react'

import ReactStrapComponent from './ReactStrapComponent'
import Field from '../Field'


const SelectField = (props:any) => <Field type="select" component={ReactStrapComponent} {...props} />



export default SelectField