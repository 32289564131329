import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'


/**
 * Client to use Firebase Authentication
 * https://firebase.google.com/docs/reference/js/firebase.auth.Auth
 */
class AuthClient {
	constructor() {
		firebase.auth().useDeviceLanguage()
	}

	/**
	 * Get user info from the database
	 * @param {string} uid - uid of the user
	 * @return - A promise with user info
	 */
	getUserInfo(uid: string): Promise<any> {
		return firebase.firestore().collection('users').doc(uid).get().then(doc => {
			return doc ? Object.assign({id: doc.id}, doc.data()) : null
		})
	}

	/**
	 * Update the email of the login user
	 * @params {string} newEmail - new email
	 * @return - A Promise with void
	 */
	updateEmail(newEmail: string): Promise<any> {
		const user = firebase.auth().currentUser
		if (user) {
			return user.updateEmail(newEmail)
		} else {
			return new Promise(reject => reject("L'utilisateur n'est pas connécté"))
		}
	}
	
	/**
	 * Update the password of the login user
	 * @params {string} newPassword - new email
	 * @return - A Promise with void
	 */
	updatePassword(newPassword: string): Promise<any> {
		const user = firebase.auth().currentUser
		if (user) {
			return user.updatePassword(newPassword)
		} else {
			return new Promise(reject => reject("L'utilisateur n'est pas connécté"))
		}
	}

	/**
	 * Create an anonymous user
	 * @param {object} customInfo - information about the user
	 * @return - A promise resolves callback data if success 
	 */
	createAnonymousUser(customInfo?: object): Promise<void> {
		return firebase.auth().signInAnonymously().then((auth: any) => {
			if (auth && auth.user) {
				//TODO add to function with auth event
				return firebase.firestore().collection('users').doc(auth.user.uid).set({
					createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
					provider: "anonymous",
					...customInfo
				})
			}
		})
	}


	/**
	 * Signup with email and password
	 * @param {string} email - email of the user's account
	 * @param {string} password - password of the user's account
	 * @param {object} customInfo - informations about the user
	 * @return - A promise resolves callback data if success 
	 */
	createUserWithEmailPassword(email: string, password: string, customInfo?: object): Promise<any> {
		const currentUser = firebase.auth().currentUser

		if (currentUser) {
			const credential = firebase.auth.EmailAuthProvider.credential(email, password)

			return currentUser.linkAndRetrieveDataWithCredential(credential).then(auth => {
				if (auth && auth.user) {
					//TODO add to function with auth event
					return firebase.firestore().collection('users').doc(auth.user.uid).update({
						email: email,
						signupAt: firebase.firestore.Timestamp.fromDate(new Date()),
						provider: "password",
						...customInfo
					}).then(() => {
						if (auth && auth.user) {
							this.getUserInfo(auth.user.uid)
						}
					})
				}
			})
		} else {
			return firebase.auth().createUserWithEmailAndPassword(email, password).then(auth => {
				if (auth && auth.user) {
					//TODO add to function with auth event
					return firebase.firestore().collection('users').doc(auth.user.uid).set({
						email: email,
						createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
						signupAt: firebase.firestore.Timestamp.fromDate(new Date()),
						provider: "password",
						...customInfo
					}).then(() => {
						if (auth && auth.user) {
							this.getUserInfo(auth.user.uid)
						}
					})
				}
			})
		}
	}


	/**
	 * Login with email and password
	 * @param {string} email - email of the user's account
	 * @param {string} password - password of the user's account
	 * @return - A promise resolves callback data if success 
	 */
	login(email: string, password: string): Promise<any> {
		return firebase.auth().signInWithEmailAndPassword(email, password)
	}

	/**
	 * Logout the current user
	 */
	logout(): Promise<void> {
		return firebase.auth().signOut()
	}

	/**
	 * Listen user changed
	 * @param cb - the callback function
	 */
	currentUser(): any {
		const currentUser = firebase.auth().currentUser

		if (currentUser) {
			return this.getUserInfo(currentUser.uid)
		} else {
			return null
		}
	}

	/**
	 * Listen user changed
	 * @param cb - the callback function
	 */
	onCurrentUser(cb: (user?: any) => void): void {
		firebase.auth().onAuthStateChanged(user => {
			if (user) {
				this.getUserInfo(user.uid).then(cb)
			} else {
				cb(null)
			}
		})
	}

	/**
	 * Ask request reset password
	 * @param {string} email - email of the user's account
	 * @return - A promise resolves if success 
	 */
	sendPasswordResetEmail(email: string): Promise<void> {
		return firebase.auth().sendPasswordResetEmail(email)
	}

	/**
	 * Ask request reset password
	 * @param {string} code - reset code 
	 * @return - A promise resolves if success 
	 */
	verifyPasswordResetCode(code: string): Promise<any> {
		return firebase.auth().verifyPasswordResetCode(code)
	}

	/**
	 * Ask request reset password
	 * @param {string} code - the confirmation code send via email to the user
	 * @param {string} password - the new password
	 * @return - A promise resolves if success 
	 */
	resetPassword(code: string, newPassword: string): Promise<void> {
		return firebase.auth().confirmPasswordReset(code, newPassword)
	}
}


export default AuthClient