import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { FirestoreClient } from 'loxamtraining-core'
import { Form } from 'loxamtraining-form'

import { Page, PageTitleBar, PageSection } from '../partials'


interface PageAddProps {
	title: string,
	path: string
}


const PageAdd: React.FC<PageAddProps> = props => {
	const firestoreClient = new FirestoreClient()
	const history = useHistory()

	const handleSumit = (values: any, actions: any) => {
		firestoreClient.add(props.path, values).then((id) => {
			history.push(`${props.path}/${id}`)
		})		
	}

	return (
		<Page>
			<PageTitleBar>
				<h1 className="page-title mr-sm-auto">{props.title}</h1> 
			</PageTitleBar>
			<PageSection>
				<Card>
					<CardBody>
						<Form onSubmit={handleSumit} initialValues={{}}>
							{ props.children }
						</Form>
					</CardBody>
				</Card>
			</PageSection>
		</Page>
	)
}


export default PageAdd